import request from '@/utils/requestV2'
const qs = require('qs')

// 根据站点ID获取站点照片集合
export function getPhotoList (data) {
  return request({
    url: '/ooh-product/v1/stationgather/getphotolist',
    method: 'POST',
    data: qs.stringify(data)
  })
}

<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="4">
        <Select
          v-model="query.assetId"
          size="small"
          placeholder="选择资产"
          @on-change="handleSearch"
        >
          <Option
            v-for="item in companyAssetArray"
            :key="'assetId_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <i-button
          size="small"
          class="m-r-5"
          type="primary"
          icon="ios-search"
          @click="handleSearch"
        >搜索</i-button>
      </i-col>
    </Row>
    <Table
      size="small"
      stripe
      :data="tableData"
      :columns="tableColumns"
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChange"
      ></Page>
    </div>
    <Modal
      v-model="detailModal"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >{{curStationInfo.name}} 详情查看</p>
      <div>
        <Row class="m-b-5">
          <i-col class="text-right">
            <Button
              v-if="curStationImages.length"
              size="small"
              type="success"
              @click="viewBigImage"
            >查看原图</Button>
          </i-col>
        </Row>
        <img-video-light-box
          ref="imgVideoLightBox"
          :showModal="detailModal"
          :sortIndex.sync="sortIndex"
        >
        </img-video-light-box>
      </div>
    </Modal>
  </div>
</template>

<script>
import ImgVideoLightBox from '@/components/common/ImgVideoLightBox'

import { companyMixin } from '@/assets/mixins/company'
import { getStationPage } from '@/api/product/station'
import { getPhotoList } from '@/api/product/stationgather'
export default {
  mixins: [companyMixin],
  components: { ImgVideoLightBox },
  data () {
    return {
      companyId: this.$store.getters.token.userInfo.companyId,
      query: {
        assetId: null,
        pageNumber: 1,
        pageSize: 15
      },
      tableData: [],
      total: 0,
      tableColumns: [
        { title: '名称', align: 'center', key: 'name' },
        // { title: '所属区域', align: 'center', key: 'regionName' },
        { title: '所在位置', align: 'center', key: 'location' },
        { title: '级别', align: 'center', key: 'stationLevelName' },
        {
          title: '是否可用',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.enabled ? '可用' : '不可用')
          }
        },
        {
          title: '是否通电',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.electric ? '通电' : '未通电')
          }
        },
        {
          title: '采集状态',
          align: 'center',
          key: 'collectstate',
          render: (h, params) => {
            switch (params.row.collectstate) {
              case 1:
                return h('span', '预录入')
              case 2:
                return h('span', '已采集')
              case 3:
                return h('span', '完成资源标准化')
              default:
                return h('span', '未知')
            }
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleViewDetail(params.row)
                  }
                }
              }, '详情')
            ])
          }
        }
      ],
      sortIndex: 0,
      detailModal: false,
      curStationInfo: {},
      curStationImages: []
    }
  },
  created () {
    this.getAssetData()
  },
  methods: {
    getAssetData () {
      this.getCompanyAssetArray().then(res => {
        this.query.assetId = res && res.length ? res[0].id : null
        this.getStationData()
      })
    },
    getStationData () {
      getStationPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.tableData = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.tableData = []
          this.total = 0
          this.query.pageNumber = 1
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.getStationData()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.getStationData()
    },
    handleViewDetail (params) {
      this.curStationInfo = params
      this.detailModal = true
      this.getStationPhotoById(this.curStationInfo.id)
    },
    getStationPhotoById (stationId) {
      getPhotoList({ stationId }).then(res => {
        if (res && !res.errcode) {
          this.curStationImages = res
          const postData = {
            startIndex: 0,
            imgList: res.length ? res.map(x => {
              return { fileKey: x.location, mimeType: 'image/jpeg' }
            }) : []
          }
          this.sortIndex = 0
          this.fileName = this.curStationInfo.name
          this.$refs.imgVideoLightBox.init(postData)
        } else {
          this.curStationImages = []
        }
      })
    },
    /**
     * 查看原图
     */
    viewBigImage () {
      this.$refs.imgVideoLightBox.viewBigImg()
    }
  }
}
</script>
